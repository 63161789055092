const mutations = {
    auth_request (state) {
        state.status = 'loading'
      },
      auth_success (state, token) {
        state.status = 'success'
        state.token = token
        localStorage.setItem('token', token)
      },
      auth_error (state) {
        state.status = 'error'
      },
      logout (state) {
        state.status = ''
        state.token = ''
        state.workspaceList = '';
        localStorage.removeItem('workspaceList')
        localStorage.removeItem('view');
      },
      update_company (state, company) {
        state.company = company
        localStorage.setItem('company', company)
      },
      update_view (state, view) {
        state.view = view
        if (view) {
          localStorage.setItem('view', view)
        }
      },
      update_workspace_list (state, list) {
        state.workspaceList = list;
        localStorage.setItem('workspaceList', list)
      },
      set_invite_token(state, token) {
        state.inviteToken = token;
      },
      content_size(state, action) {
        state.contentSize = action
      }
};

export default mutations;